import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { ISchool } from '../app/models/school';
import { ApiService } from '../app/services/api-service.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { IUser } from '../app/models/users';
import { IPlatformModule } from '../app/models/platform-module';


export const msDataResolver: ResolveFn<boolean> = (route, state) => {
  const apiService = inject(ApiService);
  const router = inject(Router);

  const userId = localStorage.getItem('currentUser');
  if (!userId) {
    console.error(`No "current user" field found in local storage`);
    router.navigate(['/']);
    return false;
  }

  const userParams = {
    path: 'Userapps',
    filter: {
      where: { id: userId },
      include: [{ roleMappings: ['school'] }],
    },
  };

  const childRoute = route.firstChild?.routeConfig?.path;
  return apiService.get<IUser>(userParams).pipe(
    map((users) => users[0]),
    switchMap((userInfo) => {
      const capabilityParams = {
        path: 'platformModules',
        filter: {
          where: {
            and: [
              { parentRoute: 'manage-professor' },
              { childRoute: childRoute },
            ],
          },
          include: ['Capability'],
        },
      };

      return apiService.get<IPlatformModule>(capabilityParams).pipe(
        map((platformModules:IPlatformModule[]) => ({userInfo, platformModules}))
      );
    }),
    switchMap(({userInfo, platformModules}) => {
      const { roleMappings, ...user } = userInfo;
      const schoolParams = {
        path: 'Schools',
        filter: {
          where: { DepenSchoolID: roleMappings![0].SchoolID },
        },
      };

      return apiService.get<ISchool>(schoolParams).pipe(
        tap((schools) => {
          const capabilities = platformModules.map(e => e.Capability).flat();
          apiService.userRoles.set(capabilities);
          const canSee = apiService.hasCapability({canSee:true})
          if (!canSee) {
            router.navigate(['/']);
            return;
          }

          apiService.userInfo.set(user);
          apiService.schools.set([...schools, roleMappings![0].school!]);
        }),
        map(() => true)
      );
    }),
    catchError((error) => {
      console.error(`Error fetching user: ${error}`);
      router.navigate(['/']);
      return of(false);
    })
  );
};
