import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IEndpoint } from '../models/endpoint';
import { IUser } from '../models/users';
import { ISchool } from '../models/school';
import { ICapabilities, ICapabilityActions } from '../models/platform-module';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _http = inject(HttpClient);

  public userRoles = signal<ICapabilities[]>([]);
  public userInfo = signal<IUser | null>(null);
  public schools = signal<ISchool[]>([]);

  private BACK_V2 = environment.URL_BACK_V2;


  /**
   * Validate current user roles
   * @param roles
   * @returns
   */
  public hasCapability(allowed: ICapabilityActions): boolean {
    if(!this.userRoles()) return false;

    const allowedKeys = Object.keys(allowed) as (keyof ICapabilityActions)[];
    return this.userRoles()!.some(capabilities => allowedKeys.some(key => capabilities[key] === allowed[key]));
  }

  private buildUrl<T>({ path, filter }: IEndpoint<T>): string {
    return `${this.BACK_V2}${path}${
      filter ? `?filter=${JSON.stringify(filter)}` : ''
    }`;
  }

  public get<T>(endpointInfo: IEndpoint<T>): Observable<T[]> {
    return this._http.get<T[]>(this.buildUrl(endpointInfo));
  }

  public put<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.put<T>(this.buildUrl(endpointInfo), endpointInfo.data);
  }

  public patch<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.patch<T>(this.buildUrl(endpointInfo), endpointInfo.data);
  }

  public post<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.post<T>(this.buildUrl(endpointInfo), endpointInfo.data);
  }

  public delete<T>(endpointInfo: IEndpoint<T>): Observable<T> {
    return this._http.delete<T>(this.buildUrl(endpointInfo));
  }
}