import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  input,
  output,
} from '@angular/core';
import { UiDropdownService } from './ui-dropdown.service';
import { Subject, takeUntil } from 'rxjs';
import { IDropdownItem } from '../models/dropdown-item';

@Component({
  selector: 'ui-dropdown',
  templateUrl: './ui-dropdown.component.html',
  styleUrl: './ui-dropdown.component.css',
  standalone: true,
  imports: [],
  providers: [UiDropdownService],
})
export class UiDropdownComponent implements OnInit, OnChanges, OnDestroy {
  private _dropdownService = inject(UiDropdownService);
  private _ref = inject(ElementRef);

  closeOnSelect = input<boolean>(true);
  useToggle = input<boolean>(true);
  onValueChange = output<IDropdownItem>();

  private _destroy$: Subject<void> = new Subject();

  /**
   * Initializes the component and subscribes to the item change observable from `UiDropdownService`.
   * Emits selected items and manages the dropdown's visibility based on the `closeOnSelect` input.
   */
  ngOnInit(): void {
    this._dropdownService.$onItemChange
      .pipe(takeUntil(this._destroy$))
      .subscribe((item) => {
        this.onValueChange.emit(item);
        if (this.closeOnSelect()) this._dropdownService.trigger.set(false);
      });
  }

  /**
   * Responds to input changes. Specifically updates the `useToggle` setting in the dropdown service.
   * @param changes - SimpleChanges object containing the changes made to inputs.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['useToggle'] && !changes['useToggle'].firstChange)
      this._dropdownService.useToggle.set(this.useToggle());
  }

  /**
   * Cleans up resources and unsubscribes from observables when the component is destroyed.
   */
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Listens for clicks outside the component and closes the dropdown if the click is outside.
   * @param event - MouseEvent object representing the click event.
   */
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (!this._ref.nativeElement.contains(target))
      this._dropdownService.trigger.set(false);
  }
}