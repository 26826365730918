import { Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular'; // AG Grid Component
import { ColDef, IDateFilterParams, GridApi} from 'ag-grid-community'; // Column Definition Type Interface
import { CustomButtonComponent } from './customButton.component';
import { NgIf, TitleCasePipe} from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormControl, AbstractControl} from '@angular/forms';
import { Observable, Subject, debounceTime, filter, forkJoin, map, switchMap, take, takeUntil } from 'rxjs';
import { ApiService } from '../../services/api-service.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { IUser } from '../../models/users';
import { IDropdownItem } from '../../models/ui/dropdown-item';
import { UiDropdownComponent } from '../ui/ui-dropdown/ui-dropdown.component';
import { UiDropdownTriggerComponent } from '../ui/ui-dropdown/ui-dropdown-trigger/ui-dropdown-trigger.component';
import { UiDropdownItemComponent } from '../ui/ui-dropdown/ui-dropdown-item/ui-dropdown-item.component';
import { UiDropdownContentComponent } from '../ui/ui-dropdown/ui-dropdown-content/ui-dropdown-content.component';
import { UiInputComponent } from '../ui/ui-input/ui-input.component';
import { SweetalertService } from '../../services/sweetalert.service';
import { CellRenderer } from './editButtonComponent';
import {  ISelectCellEditorParams } from "ag-grid-community";
import { Contracts } from '../../models/contracts';
import { HasCapabilityDirective } from '../../shared/directives/has-capabilities';


declare let $: any;
@Component({
  selector: 'app-manage-professor',
  standalone: true,
  imports: [AgGridAngular,NgIf,ReactiveFormsModule,UiDropdownComponent,UiDropdownTriggerComponent,UiDropdownItemComponent,UiDropdownContentComponent,UiInputComponent,TitleCasePipe,CellRenderer,HasCapabilityDirective],
  templateUrl: './manage-professor.component.html',
  styleUrl: './manage-professor.component.css'
})


export class ManageProfessorComponent implements OnInit,OnDestroy{
  EnableTable: boolean = true;
  collapseCards: number = 1;
  EnableViewJobs: boolean = true;
  contractType: any[] = [];
  contractList: Contracts[]=[];
  jobsList: any[]=[];
  ScnoolAcronim = `${environment.PRINCIPAL_APP_NAME.toUpperCase()}`
  SchoolID: number=1;
  ALL_MY_SCHOOL_DATA: any;
  userJobsContractsBySchoolID: any[] = [];
  person: IUser[] = [];
  typeContract: string = "";
  private _destroy$: Subject<void> = new Subject();
  private gridApi!: GridApi;
  //Form

  form: FormGroup = this.formBuilder.group(
    {
      InfoSelectedEmail:["", Validators.required],
      UserIdSelected:[""],
      TypeContractID:[0, Validators.required],
      isActive:["", Validators.required],
      StartContract:["", Validators.required],
      EndContract:["", Validators.required],
      ContractNumber:[""],
    },
  );

  submited = false;

  constructor (
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private _sanitizer: DomSanitizer,
    private _sweetalertService: SweetalertService,

  ){
    this.getUserData();
  };

  ngOnInit(): void {

    this.getRoleData();
    this.form = this.formBuilder.group(
      {
        InfoSelectedEmail:["", Validators.required],
        UserIdSelected:[""],
        TypeContractID:[0, Validators.required],
        isActive:["", Validators.required],
        StartContract:["", Validators.required],
        EndContract:["", Validators.required],
        ContractNumber:[""],
      },
    );

    this._guestSubject$
      .pipe(
        takeUntil(this._destroy$),
        filter((value) => value.length > 1),
        debounceTime(300),
        map((value) => this.guestQry(value.toLowerCase())),
      ).subscribe((guests: any[]) => {
        this._filteredGuests.set(guests);
      });

      this.form.get('TypeContractID')!.valueChanges.subscribe(value => {
        this.typeContract = value;
      });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  checkErrors = false;

  onSubmit(): void {
    this.submited = true;
 
    if(this.typeContract == "7"){
      let actualYear = this.form.get("StartContract")?.value?.split("-")[0];
      let newYear = Number(actualYear) + 7;
      this.form.get("EndContract")?.setValue(`${newYear}-12-31`)
    }


    if (this.form.invalid || !this.validateDateRange()) {
      console.log("invalido");
      return;
    }
      this.checkErrors = true;
      const data = this.form.value;
      data.UserID = data.UserIdSelected;
      data.SchoolID = this.SchoolID;
      let filterContracts = {include: ["TypeContracts","Userapps"]};
      this._sweetalertService.swalLoading('Cargando','Por favor, espere');
      // const canSave = this.apiService.hasCapability({canEdit: true}); /** TODO: Review capabilities */
      // if (!canSave) {
      //   this._sweetalertService.swalError('Error','No tiene los permisos necesarios',()=>{});
      //   return 
      // }
      this.apiService.patch({path:"/UserJobsContracts",data:data}).pipe(
        switchMap(_ =>  this.apiService.get({path:`/Schools/${this.SchoolID}/UserJobsContracts`,filter: filterContracts}))
      ).subscribe({
        next:(resp:any)=>{
          this.contractList = resp;
          this._sweetalertService.swalSuccess('Success','Registro creado exitosamente', ()=>{ 
            this.form.reset();
            });
          this.processContractList();
            console.log("Respuesta servidor",resp);
        },error:(err)=>{
          console.log(err);
          this._sweetalertService.swalError('Error', 'No se pudo realizar la acción correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
        }
      })
  }

  onUpdateIsActive(dataReceived:any): void {
    let contractId = dataReceived.data.id;
    let value = (dataReceived.newValue == "Si");
      const data = {isActive:value};
      this._sweetalertService.swalLoading('Cargando','Por favor, espere');
      this.apiService.patch({path:`/UserJobsContracts/${contractId}`,data:data}).pipe(take(1)).subscribe({
        next:(resp:any)=>{
          this._sweetalertService.swalSuccess('Success','Registro actualizado exitosamente', ()=>{});
          console.log("Respuesta servidor",resp);
        },error:(err)=>{
          console.log(err);
          this._sweetalertService.swalError('Error', 'No se pudo realizar la acción correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
        }
      })
    
  }
  private guestQry(value: string): IUser[]{
    let result = this.jobsList.filter(x=>(
      x.userapp.CedocEmail.includes(value) ||
      x.userapp.UserDocuments[0].Document.includes(value) 
    )).map(x=> x.userapp).slice(0,4);
    return result
  }

  validateDateRange(){
    const startDate = this.form.get('StartContract')!.value;
    const endDate = this.form.get('EndContract')!.value;

    
    if(!startDate || !endDate)
      return false;

    if(startDate > endDate){
      console.log("Ha ocurrido un error", 'La fecha de inicio no puede ser mayor a la de finalización');
      return false;
    }

    return true;
  }

  changeView(index: number): void{
    if(this.collapseCards==index)this.collapseCards=-1;
    else this.collapseCards=index;
  }


  getRoleData(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    let subTypeReqFilter = JSON.stringify({
      include: {
        relation: 'reqMembersTemplate',
        scope: {
          order: 'numSequence ASC',
          include: ["user"]
        }
      }
    });
    forkJoin({
      MY_ROLE_LIST: this.apiService.get({path:"/Userapps/me/roleMappings"}).pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        let {MY_ROLE_LIST} = data;
        this.SchoolID = MY_ROLE_LIST[0].SchoolID;
        this._sweetalertService.swalClose();
        this.getAllInitialDataReqResume();
      },
      error: (err) => {
        console.log("ERROR getAllInitialDataReqResume",err);
        this._sweetalertService.swalError('Error','No se pudo cargar la información necesaria, intentelo nuevamente, si el error persiste, contacte a soporte.', ()=>{});
      },
      complete: () => {
        console.log('complete getAllInitialDataReqResume');
      }
    });
  }

  getAllInitialDataReqResume(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    let schoolFilter = { path: `/Schools/${this.SchoolID}` };
    let contractTypeListFilter = { path :"/TypeContracts" };
    let contractListFilter = {
      path: `/Schools/${this.SchoolID}/UserJobsContracts`,
      filter: { 
        include: ["TypeContracts", "Userapps"]
      }
    };
    let jobListFilter = {
      path: `/Schools/${this.SchoolID}/UserJobs`,
      filter: { 
        include: {
          relation:  "userapp",
          scope: { include: [ "UserDocuments"] }
        }
      }
    };

    forkJoin({
      ALL_SCHOOLS_LIST: this.apiService.get( schoolFilter ).pipe(take(1)),
      ALL_CONTRACT_TYPE_LIST: this.apiService.get( contractTypeListFilter ).pipe(take(1)),
      ALL_CONTRACT_LIST: this.apiService.get( contractListFilter ).pipe(take(1)),
      ALL_JOBS_LIST: this.apiService.get( jobListFilter ).pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        let { ALL_SCHOOLS_LIST, ALL_CONTRACT_TYPE_LIST, ALL_CONTRACT_LIST, ALL_JOBS_LIST } = data;
        this.contractType = ALL_CONTRACT_TYPE_LIST;
        this.contractList = ALL_CONTRACT_LIST;
        this.jobsList = ALL_JOBS_LIST;
        this.ALL_MY_SCHOOL_DATA = ALL_SCHOOLS_LIST; 
        this.processContractList();
        this.processJobsList();
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("ERROR getAllInitialDataReqResume",err)
        this._sweetalertService.swalError('Error','No se pudo cargar la información necesaria, intentelo nuevamente, si el error persiste, contacte a soporte.', ()=>{});
      },
      complete: () => {
        console.log('complete getAllInitialDataReqResume');
      }
    });
  }

  getUserData(): void{
    let userID = Number(localStorage.getItem( "currentUser" ));
  }

  // Función auxiliar para procesar la lista de contratos
  processContractList(): void {
    this.contractList.forEach(element =>{
      if(element.Userapps){
        let NombreCompleto = $.grep([element.Userapps["Name1"], element.Userapps["Name2"]], Boolean).join(" ");
        let ApellidoCompleto = $.grep([element.Userapps["LastName1"], element.Userapps["LastName2"]], Boolean).join(" ");
        let actionsVar = false;
        element.actionVar = actionsVar;
        element.FullName = (ApellidoCompleto + ' ' + NombreCompleto).toUpperCase();
        element.Email = element.Userapps.CedocEmail;
        element.isActiveDisplay = element.isActive ? "Si":"No";
      }
    });

    this.contractList = this.contractList.sort(function (a, b) {
      return (a["FullName"] && b["FullName"]) ? a["FullName"].localeCompare(b["FullName"]) : 0;
    });
  }

  // Función auxiliar para procesar la lista de trabajos
  processJobsList(): void {
    this.jobsList.forEach(element =>{
      if(element.userapp){
        var NombreCompleto = $.grep([element.userapp["Name1"], element.userapp["Name2"]], Boolean).join(" ");
        var ApellidoCompleto = $.grep([element.userapp["LastName1"], element.userapp["LastName2"]], Boolean).join(" ");
        element.FullName = (ApellidoCompleto + ' ' + NombreCompleto).toUpperCase();
        element.Email = element.userapp.CedocEmail;
      }
    });

    this.jobsList = this.jobsList.sort(function(a,b){
      return (a["FullName"] && b["FullName"]) ? a["FullName"].localeCompare(b["FullName"]) : 0;
    });
  }

  private _filteredGuests = signal<IUser[]>([]);
  private _selectedGuests = signal<(IUser & { eventUserId?: number })[]>([]);
  private _guestSubject$: Subject<string> = new Subject();
  
  public selectGuest(value: string) {
    const getUserInfo = this._filteredGuests().find((e) => e.id === Number(value));
    this.form.patchValue({InfoSelectedEmail: getUserInfo?.email});
    this.form.patchValue({UserIdSelected: getUserInfo?.id});
  }

  public findGuest(event: any | number) {
    if(event)
    this._guestSubject$.next(event.toString());
  }

  public guests = computed<{ name: string; id: number }[]>(() => {
    console.log(this._filteredGuests());
    return this._filteredGuests().map((e) => ({
      name: this.clearName(e),
      id: e.id!,
    }));
  });
  
  private clearName(user: IUser) {
    return `${user.Name1} ${user.Name2 || ''} ${user.LastName1} ${
      user.LastName2 || ''
    }`.replace(/\s+/g, ' ');
  }

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
  };
  optionsSelectAgGrid = [true, false];
  // Definicion de columnas y filtros de cada una.
  colDefs: ColDef[] = [
    { field: "FullName" ,
    headerName: "NOMBRE DOCENTE",
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true
    },
    { field: "Userapps.CedocEmail" ,
    headerName: "VER HOJA DE VIDA",
    suppressHeaderMenuButton: true,
    cellRenderer: CustomButtonComponent
    },
    { field: "TypeContracts.NameTypeContract" ,
    suppressHeaderMenuButton: true,
    headerName: "TIPO DE VINCULACIÓN"
    },
    { field: "StartContract" ,
    headerName: "FECHA INICIO",
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    filter: "agDateColumnFilter",
    filterParams: filterParams,
    
    },
    { field: "EndContract" ,
    headerName: "FECHA FIN",
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    filter: "agDateColumnFilter",
    filterParams: filterParamsEndDate,
    },
    { field: "isActiveDisplay" ,
      headerName: "ACTIVO",
      suppressHeaderMenuButton: true,
      editable: true,
      onCellValueChanged: this.onUpdateIsActive.bind(this),
      
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Si","No"],
      } as ISelectCellEditorParams,
      cellRenderer: CellRenderer,
    },
  ];

  public context = { componentParent: this };

  public defaultColDef2: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
  };

  colDefs2: ColDef[]=[
    { field: "FullName" ,
    headerName: "NOMBRE DOCENTE",
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,    
    },
    { field: "Email" ,
    headerName: "VER HOJA DE VIDA",
    floatingFilter: false,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,  
    filter: 'agTextColumnFilter',
    cellRenderer: CustomButtonComponent,
    },
  ];

  autocompleListFormatter = (data: any) => {
    let CompleteName = $.grep([data.LastName1, data.LastName2, data.Name1, data.Name2], Boolean).join(" ");
    let html = `<b>Correo:</b> <i>${data.CedocEmail}</i> - ${CompleteName}, C.C. ${data.UserDocuments[0].Document} `;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

//Filtro fecha 1 
var filterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("-");
      var cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2]),
      );
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 0;
      }
      return 0;
    },
    minValidYear: 2000,
}
//Filtro fecha 2
var filterParamsEndDate: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("-");
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]),
    );
    if (cellDate > filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return 0;
    }
    return 0;
  },
  minValidYear: 2000,
}

