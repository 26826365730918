// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[0].use[2]!../node_modules/ag-grid-community/styles/ag-grid.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[0].use[2]!../node_modules/ag-grid-community/styles/ag-theme-quartz.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Temas especificos del ag-grid*/


.autohide {
    display: none;
}

/*Estilos del ag grid*/
.ag-header-cell.ag-column-first {
    color: black;
    /* max-width: 100px; */
  }
  
  .ag-header-cell {
    color: #1e6bb8;
    text-decoration: none;
    font-weight: 700;
  }
  
  .ag-floating-filter {
    color: black;
  }

  .ag-theme-quartz {
    font-family: "PT Sans", sans-serif;
    font-size: .875em;
    --ag-borders: none;
    --ag-borders-input: solid 1px;
    --ag-borders-secondary: solid 1px;
    --ag-header-background-color: none;
  }
  
  .button-icon-ag{
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,gCAAgC;;;AAKhC;IACI,aAAa;AACjB;;AAEA,sBAAsB;AACtB;IACI,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,gBAAgB;EAClB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,kCAAkC;IAClC,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;IAC7B,iCAAiC;IACjC,kCAAkC;EACpC;;EAEA;IACE,eAAe;EACjB","sourcesContent":["/*Temas especificos del ag-grid*/\n@import 'ag-grid-community/styles/ag-grid.css';\n@import 'ag-grid-community/styles/ag-theme-quartz.css';\n\n\n.autohide {\n    display: none;\n}\n\n/*Estilos del ag grid*/\n.ag-header-cell.ag-column-first {\n    color: black;\n    /* max-width: 100px; */\n  }\n  \n  .ag-header-cell {\n    color: #1e6bb8;\n    text-decoration: none;\n    font-weight: 700;\n  }\n  \n  .ag-floating-filter {\n    color: black;\n  }\n\n  .ag-theme-quartz {\n    font-family: \"PT Sans\", sans-serif;\n    font-size: .875em;\n    --ag-borders: none;\n    --ag-borders-input: solid 1px;\n    --ag-borders-secondary: solid 1px;\n    --ag-header-background-color: none;\n  }\n  \n  .button-icon-ag{\n    cursor: pointer;\n  }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
