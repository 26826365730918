import { Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ManageProfessorComponent } from './components/manage-professor/manage-professor.component';
import { msDataResolver } from '../resolvers/ms-data-resolver.resolver';

export const routes: Routes = [
    { path: 'manage-professor',
        resolve:{
            msData: msDataResolver
        },
        children:[
            {
                path: 'manageUsers',
                component: ManageProfessorComponent,
            }
        ] 
        },
    { path: '**', component: EmptyRouteComponent },
];
