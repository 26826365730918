<div>
    <div class="cat__core__sortable" id="left-col">
 
       <!-- First Section 
        *hasCapability="{canEdit:true, canCreate: false}; else error"        
        <ng-template #error>
        prueba
       </ng-template> -->
       <section class="card" (click)="changeView(0)">
       <!-- <section class="card" *ngIf="seccion1"> -->
          <div class="card-header" style="border-left-style: solid; border-left-color: #EE3F31; border-left-width: 7px;">
 
             <div class="pull-right cat__core__sortable__control" >
 
                 @if (collapseCards==0) {
                     <i class="fa fa-toggle-on fa-2x mr-2 " style="color: #1F9DFE"></i>
                 }
                 @else {
                     <i class="fa fa-toggle-off fa-2x mr-2 "></i>
                 }
                
             </div>
 
              <h5 class="mb-0 text-black uppercase">
 
                  <i class="icon" [class.icon-i-pencil]='collapseCards==0' [class.icon-i-add]='collapseCards!=0'>
                  </i>
                  <strong >
                      Agregar Registro
                  </strong>
                  <small class="text-muted" style="font-size: 1rem; text-transform: uppercase;">
                  </small>
 
              </h5>
 
              @if (collapseCards==0) {
                 <div class="card-block" *ngIf="EnableTable" (click)="$event.stopPropagation()">
                     <form [formGroup]="form" (ngSubmit)="onSubmit()">
                         <div class="row">
                             <div class="col-lg-1 col-md-12"></div>
                             <div class="col-lg-10 col-md-12">
                                 <div class="row">
                                     <div class="col-sm-12 col-xs-12">
                                         <div class="form-group">
                                             <label class="form-label">{{ALL_MY_SCHOOL_DATA['NameTSchool'] || "" }}</label>
                                         </div>
                                     </div>
                                 </div>        
                                 <div class="row">
                                     <div class="col-sm-4 col-xs-12">  
                                        <div>
                                        <label for="InfoSelectedEmail">Documento/Correo del Profesor:</label>
                                        <ui-dropdown
                                                (onValueChange)="selectGuest($event.value)"
                                                [closeOnSelect]="true"
                                            >
                                                <ui-dropdown-trigger>
                                                <ui-input
                                                type="text" ng2-auto-complete formControlName="InfoSelectedEmail"
                                                placeholder="Buscar por correo o documento"
                                                value-property-name="CedocEmail" display-property-name="email"
                                                name="email" min-chars="3" no-match-found-text="No Se Encontró Ningun Resultado" class="form-control"
                                                autocomplete="off" required
                                                id="InfoSelectedEmail" formControlName="InfoSelectedEmail" 
                                                (valueChange)="findGuest($event)"
                                                value = "Correo o documento">
                                              
                                                </ui-input>
                                                </ui-dropdown-trigger>
                                
                                                @if(guests().length > 0){
                                                <ui-dropdown-content>
                                                @for (guest of guests(); track $index) {
                                                <ui-dropdown-item [value]="guest.id">
                                                    {{ guest.name | titlecase }}
                                                </ui-dropdown-item>
                                                }
                                                </ui-dropdown-content>
                                                }
                                            </ui-dropdown> 
                                            <div class="form-control-error-list" >
                                                @if (submited && f['InfoSelectedEmail'].errors) {
                                                    <ul>
                                                        <li>Este campo es obligatorio</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                     </div>
                                     <div class="col-sm-4 col-xs-12">
                                         <div class="form-group">
                                             <label>Tipo de vinculación</label>
             
                                             <select class="form-control" formControlName="TypeContractID">
                                                 <option value="0" disabled>Seleccionar tipo de vinculación</option>
                                                 @for (typeC of contractType; track typeC.id) {
                                                     <option [value]="typeC.id">{{typeC.NameTypeContract}}</option>
                                                 }
                                             </select>
                                             <div class="form-control-error-list" >
                                                 @if (submited && f['TypeContractID'].errors) {
                                                     <ul>
                                                         <li>Este campo es obligatorio</li>
                                                     </ul>
                                                 }
                                             </div>
                                         </div>
                                     </div>
 
                                     <!-- Activo -->
                                     <div class="col-sm-4 col-xs-12">
                                         <div class="form-group">
                                             <label class="form-label">Docente activo</label>
                                             <select class="form-control" formControlName="isActive">
                                                 <option value="" selected disabled>Seleccionar</option>
                                                 <option [value]="true">Activo</option>
                                                 <option [value]="false">No Activo</option>
                                             </select>
 
                                             <div class="form-control-error-list" >
                                                 @if (submited && f['isActive'].errors) {
                                                     <ul>
                                                         <li>Este campo es obligatorio</li>
                                                     </ul>
                                                 }
                                             </div>
                                         </div>
                                     </div>
 
                                 </div>
 
                                 <div class="row">
                                     <!-- Fecha de inicio -->
                                     <div class="col-sm-4 col-xs-12">
                                         <div class="form-group">
                                             <label class="form-label">Fecha de inicio</label>
                                             <input type="date" formControlName="StartContract" class="form-control" required /> <!-- TODO: VALIDAR QUE LA FECHA DE INICIO NO SEA MAYOR A LA FINAL -->
                                             <div class="form-control-error-list">
                                                 @if (submited && f['StartContract'].errors) {
                                                     <ul>
                                                         <li>Este campo es obligatorio</li>
                                                     </ul>
                                                 }
                                             </div>
                                         </div>
 
                                     </div>
                                     <!-- Fecha de finald el contrato, si el Id de tipo de vinculacion es 7 la fecha de finalizacion se oculta -->
                                     @if (typeContract != "7") {
                                         <div class="col-sm-4 col-xs-12">
                                             <div class="form-group">
                                                 <label>Fecha de finalización</label>
                                                 <input type="date" formControlName="EndContract" class="form-control" required />
                                                 <div class="form-control-error-list">
                                                     @if (submited && f['EndContract'].errors) {
                                                         <ul>
                                                             <li>Este campo es obligatorio</li>
                                                         </ul>
                                                     }
                                                 </div>
                                             </div>
     
                                         </div>
                                     }

                            
                                     <!-- Numero de contrato y horas totales ocultas 
                                         <div class="col-sm-2 col-xs-12">
 
                                         <div class="form-group">
 
                                             <label class="form-label">
                                                 Número de contrato:
                                             </label>
 
                                             <input type="text" formControlName="ContractNumber" class="form-control"
                                                 placeholder="Numero de contrato" minlength="1" maxlength="70" #required />
                                                 <div class="form-control-error-list">
                                                     @if (submited && f['ContractNumber'].errors) {
                                                     <ul>
                                                         <li>Este campo es obligatorio</li>
                                                     </ul>
                                                 }
                                                 </div>
                                         </div>
 
                                     </div>
                                 
                                     <div class="col-sm-2 col-xs-12">
                                         <div class="form-group">
                                             <label class="form-label">Horas totales del contrato</label>
                                             <input type="text" formControlName="ContractHours" class="form-control"
                                                 placeholder="Hotas totales del contrato" minlength="1" maxlength="4" />
                                             <div class="form-control-error-list">
                                                @if (submited && f['ContractHours'].errors) {
                                                     <ul>
                                                         <li>Este campo es obligatorio</li>
                                                     </ul>
                                                 }
                                             </div>
                                         </div>
 
                                     </div> -->
 
                                 </div>
 
                                 <button type="submit" [disabled]="" class="btn btn-primary mr-3">
                                     Guardar
                                 </button>
 
                             </div>
 
                         </div>
 
                     </form>                             
                 </div>
              }
 
          </div>
       </section>
 
       <!-- Second Section-->
       <section class="card" (click)="changeView(1)">
         <div class="card-header" style="border-left-style: solid; border-left-color: #31ee82; border-left-width: 7px;" >
           
             <div class="pull-right cat__core__sortable__control" >
 
                 @if (collapseCards==1) {
                     <i class="fa fa-toggle-on fa-2x mr-2 " style="color: #1F9DFE"></i>
                 }
                 @else {
                     <i class="fa fa-toggle-off fa-2x mr-2 "></i>
                 }
                
             </div>
             
             <h5 class="mb-0 text-black uppercase">
                 
                 <i class="icon" [class.icon-i-pencil]='collapseCards==1' [class.icon-i-add]='collapseCards!=1'>
                 </i>
                <strong > Gestionar Docentes</strong>
                <small class="text-muted"></small>
             </h5>
             @if (collapseCards==1) {
                 <div class="card-block" *ngIf="EnableTable" (click)="$event.stopPropagation()">
     
                     <ag-grid-angular
                         class="ag-theme-quartz"
                         style="width:100%; height: 500px;"
                         [rowData]="contractList"
                         [columnDefs]="colDefs"
                         [defaultColDef]="defaultColDef"
                         [suppressClickEdit]="true"
                         [context]="context"
                         >
                     </ag-grid-angular>
     
                 </div> 
             }
 
         </div>
       </section>
 
       <!--Third Section-->
       <section class="card" (click)="changeView(2)">
 
          <div class="card-header" style="border-left-style: solid; border-left-color: #0045ff; border-left-width: 7px;">
 
             <div class="pull-right cat__core__sortable__control" >
 
                 @if (collapseCards==2) {
                     <i class="fa fa-toggle-on fa-2x mr-2 " style="color: #1F9DFE"></i>
                 }
                 @else {
                     <i class="fa fa-toggle-off fa-2x mr-2 "></i>
                 }
                
             </div>
 
              <h5 class="mb-0 text-black uppercase">
 
                 <i class="icon" [class.icon-i-pencil]='collapseCards==2' [class.icon-i-add]='collapseCards!=2'>
                 </i>
                  <strong > Usuarios inscritos a la escuela</strong>
                  <small class="text-muted"></small>
 
              </h5>
 
              @if (collapseCards==2) {
 
                 <div class="card-block" *ngIf="EnableViewJobs"  (click)="$event.stopPropagation()" >
                    <ag-grid-angular
                    class="ag-theme-quartz"
                    style="width:100%; height: 500px;"
                    [rowData]="jobsList"
                    [columnDefs]="colDefs2"
                    [defaultColDef]="defaultColDef2"
                    >
                 </ag-grid-angular>
                
                 </div>
    
             }
 
          </div>
          
      </section>
 </div>