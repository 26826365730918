import { Component, computed, ElementRef, inject } from '@angular/core';
import { UiDropdownService } from '../ui-dropdown.service';
import { CommonModule, DOCUMENT } from '@angular/common';

@Component({
  selector: 'ui-dropdown-content',
  templateUrl: './ui-dropdown-content.component.html',
  styleUrl: './ui-dropdown-content.component.css',
  imports: [CommonModule],
  standalone:true
})
export class UiDropdownContentComponent {
  private _ref = inject(ElementRef);
  private DOCUMENT = inject(DOCUMENT);
  private _dropdownService = inject(UiDropdownService);

  public open = computed(() => this._dropdownService.trigger());

  /**
   * `isRightPosition` is a computed property that determines if the dropdown content is positioned correctly within the viewport.
   * It checks if the content overflows the right edge of the window and adjusts the position accordingly.
   */
  public isRightPosition = computed(() => {
    if(!this.open())
      return false;

    const windowWidth = this.DOCUMENT.body.getBoundingClientRect().width;
    const contentHolder = this._ref.nativeElement.querySelector('.content-holder');
    const boxRight = contentHolder.getBoundingClientRect().right;
    
   return (windowWidth < boxRight) && this.open();
  });

  /**
   * `isBottomPosition` is a computed property that determines if the dropdown content is positioned correctly within the viewport.
   * It checks if the content overflows the bottom edge of the window and adjusts the position accordingly.
   */
  public isBottonPosition = computed(() => {
    if(!this.open())
      return false;

    const windowHeight = this.DOCUMENT.documentElement.clientHeight;
    const contentHolder = this._ref.nativeElement.querySelector('.content-holder');
    
    const boxBottom = contentHolder.getBoundingClientRect().bottom;
    return (windowHeight < boxBottom) && this.open();
  });
}
