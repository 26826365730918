import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { IDropdownItem } from '../models/dropdown-item';

@Injectable({
  providedIn: 'root'
})
export class UiDropdownService {
  public $onItemChange = new Subject<IDropdownItem>();
  public trigger = signal<boolean>(false);
  public useToggle = signal<boolean>(false);

  /**
   * Sets the selected item and notifies subscribers through the `$onItemChange` subject.
   * @param item - The selected dropdown item.
   */
  public setItem(item:IDropdownItem){
    this.$onItemChange.next(item);
  }
}
