import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { Component } from '@angular/core';

@Component({
  standalone: true,
  template: `<a target="_blank" [href]="ver_hoja_de_vida" class ="renderedButton">VER HOJA DE VIDA</a>`,
  styleUrl: './customButton.component.css'
})
export class CustomButtonComponent implements ICellRendererAngularComp {
  ver_hoja_de_vida: string = "";

  agInit(params: ICellRendererParams): void {
    this.ver_hoja_de_vida = `/Cv-Public/${params.value}`;
  }
  refresh(params: ICellRendererParams) {
    return true;
  }
 
}