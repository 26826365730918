<div class="input-container">
  <div
    [class]="styles()"
    [ngClass]="{
      'input-field--active': clickedInside() || placeholder() || value() || decorator.childNodes.length > 0,
      'disabled-input': isDisabled(),
      'no-label': !label()
    }"
    (click)="clickedInside.set(true); inputElement.focus()"
    (keyup)="onKeyup($event)"
  >
    <label class="field-label">{{ label() }}</label>
    <div class="input-field__content">
      <div class="decorator" #decorator>
        <ng-content select="[start-decorator]" />
      </div>
      <input
        class="input"
        [type]="type()"
        [placeholder]="placeholder()"
        autocomplete="off"
        (blur)="onTouch()()"
        [ngModel]="value()"
        (ngModelChange)="value.set($event)"
        #inputElement
      />
    </div>
  </div>
</div>
