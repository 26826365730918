import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    standalone: true,
    template: `<span class="button-icon-ag">
        <i class="icon-i-pencil" (click)="onClick()"></i>
        <span style="padding-left: 4px;">{{this.displayValue}}</span>
    </span>`
})
export class CellRenderer implements ICellRendererAngularComp {
    private params!: ICellRendererParams<any, string>;
    public displayValue!: string;

    agInit(params: ICellRendererParams<any, string>): void {
        this.params = params;
        this.displayValue = params.data.isActiveDisplay;
    }

    onClick() {
        this.params.api.startEditingCell({
          rowIndex: this.params.node.rowIndex!,
          colKey: this.params.column!.getId(),
        });
    }
    
    refresh(params: ICellRendererParams) {
        return false;
    }
}