import { Component, ElementRef, inject, input } from '@angular/core';
import { UiDropdownService } from '../ui-dropdown.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-dropdown-item',
  templateUrl: './ui-dropdown-item.component.html',
  styleUrl: './ui-dropdown-item.component.css',
  standalone: true,
  imports:[CommonModule]
})
export class UiDropdownItemComponent {
  private _ref = inject<ElementRef>(ElementRef);
  private _dropdownService = inject(UiDropdownService);

  value = input.required<string | number>();
  disabled = input<boolean>(false);

  /**
   * `setItem` sets the current item as selected in the dropdown service.
   * If the item is disabled, the method does nothing.
   */
  public setItem() {
    if(this.disabled())
      return;

    const label = this._ref.nativeElement.querySelector('.dropdown-item__content')?.
      textContent?.toString().trim() || '';
    this._dropdownService.setItem({ value: this.value().toString(), label });
  }

  public preventDefault(event: Event) {
    event.preventDefault();
  }
}
