import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  computed,
  forwardRef,
  inject,
  input,
  model,
  signal,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-input',
  templateUrl: './ui-input.component.html',
  styleUrl: './ui-input.component.css',
  standalone:true,
  imports:[CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputComponent),
      multi: true,
    },
  ],
})
export class UiInputComponent implements ControlValueAccessor {
  private _ref = inject(ElementRef);

  type = input<'email' | 'text' | 'number'>('text');
  label = input<string>('');
  placeholder = input<string>('');
  invalid = input<boolean>(false);
  value = model<string | number>('');
  variant = input<'flat' | 'bordered' | 'underline'>('flat');
  private _controlValue = signal<string | number>('');
  public clickedInside = signal<boolean>(false);
  public styles = computed(() => `input-field ${this.variant()} ${
    this.invalid() ? 'invalid-field invalid-field--' + this.variant() : ''
  }`)

  //#region ACCESSORS
  private _onChange = signal<(_: any) => void>(_ => {});
  public onTouch = signal<() => void>(() => {});
  public isDisabled = signal<boolean>(false);
  //#endregion

  /**
   * On set data
   * @param event
   */
  public onKeyup(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    //Update form control value
    this._onChange.update(_ => {
      _(value);
      return _;
    });
  }

  //#region ACCESSORS
  writeValue(value: any): void {
    this.value.set(value);
    this._controlValue.set(value);
  }

  registerOnChange(fn: any): void {
    this._onChange.set(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch.set(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  //#endregion

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (
      !this._ref.nativeElement.contains(target) &&
      this.value()?.toString().length === 0
    )
      this.clickedInside.set(false);
  }
}
